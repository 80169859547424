function mainNavigation() {

  let nav = document.querySelector('.js-toggle-menu')
  let navMenu = document.querySelector('.js-nav-menu')
  let menuItems = navMenu.querySelectorAll('.menu-item-has-children > a')

  /**
   * Closes all menus and submenus
   *
   * @return  {[type]}  [return description]
   */
  let closeAllMenus = function (whatClassNot) {
    // Remove open state classes from the body
    for (let i = document.body.classList.length - 1; i >= 0; i--) {
      let classToRemove = document.body.classList[i]

      if (classToRemove.endsWith('-is-open') && classToRemove != whatClassNot) {
        document.body.classList.remove(classToRemove)
      }
    }

    // Close any opened submenu
    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i].getAttribute('aria-expanded') == 'true') {
        menuItems[i].setAttribute('aria-expanded', 'false')
      }
    }
  }

  nav.addEventListener('click', function () {
    closeAllMenus('nav-is-open')
    document.body.classList.toggle('nav-is-open')
  })

  // Arias
  if (menuItems.length) {
    for (let i = menuItems.length - 1; i >= 0; i--) {
      menuItems[i].setAttribute('aria-expanded', 'false');
    }
  }


  // let toggleMenus = function (event) {
  //   event.preventDefault()

  //   if (window.innerWidth <= 1024) {
  //     const itemToggle = this.getAttribute('aria-expanded');

  //     for (let i = 0; i < menuItems.length; i++) {
  //       menuItems[i].setAttribute('aria-expanded', 'false');
  //     }

  //     if (itemToggle == 'false') {
  //       this.setAttribute('aria-expanded', 'true')
  //     }
  //   }
  // }

  // menuItems.forEach(item => item.addEventListener('click', toggleMenus))
}

export default mainNavigation
