function smoothScrollTo() {

  // Smooth scroll to anchor function
  const smoothScrollTo = function (target) {
    let headerOffset = 48;
    let elementPosition = target.offsetTop;
    let offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  // Scroll to onload page
  window.onload = function() {
    if (window.location.hash) {
      let target = document.querySelector(window.location.hash);
      smoothScrollTo(target);
    }
  }

  // Scroll to when link with anchor is clicked
  document
    .querySelectorAll('a[href*="#"]')
    .forEach(trigger => {

      if (trigger.getAttribute('href').length > 1) {

        trigger.onclick = function (e) {
          let hash = '#' + this.getAttribute('href').split('#')[1];
          let target = document.querySelector(hash);

          if (target != null) {
            e.preventDefault();
            smoothScrollTo(target)
          }

        };

      }
    });
}

export default smoothScrollTo
