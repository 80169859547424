import mainNavigation from '../components/nav';
import accordionItem from '../components/accordion';
import smoothScrollTo from '../components/smooth-scrollto';
import catBar from '../components/cat-bar';
import customSelect from '../components/custom-select';
import animations from '../components/animations';

export default {
  init() {
    // JavaScript to be fired on all pages
    mainNavigation();
    accordionItem();
    smoothScrollTo();
    catBar();
    customSelect();

    // window.addEventListener('resize', catBar);

    // Cookies vars
    if(
      document.querySelector('th.cookielawinfo-column-1') &&
      document.querySelector('th.cookielawinfo-column-2') &&
      document.querySelector('th.cookielawinfo-column-3') &&
      document.querySelector('th.cookielawinfo-column-4')
    ) {
      document.querySelector('th.cookielawinfo-column-1').innerHTML = 'Cookie';
      document.querySelector('th.cookielawinfo-column-2').innerHTML = 'Tipo';
      document.querySelector('th.cookielawinfo-column-3').innerHTML = 'Duración';
      document.querySelector('th.cookielawinfo-column-4').innerHTML = 'Descripción';
    }

    let companyCarousels = document.querySelectorAll('.js-company-carousel');

    if (companyCarousels.length > 0) {
      companyCarousels.forEach(carousel => {

        $(carousel).slick({
          arrows: true, // Adds arrow nav
          dots: false, // Adds bullets nav
          autoplay: true,
          autoplaySpeed: 5000, // Time between slide changes
          speed: 1500, // Time on fade in and out every slide
          useTransform: true,
          cssEase: 'ease-in-out',
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: false,
          // centerMode: true,
          // Breakpoints for responsive
          mobileFirst: true, // Makes library to work mobile first
          responsive: [
            {
              breakpoint: 320,
              settings: {
                arrows: true,
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                arrows: true,
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                arrows: true,
                speed: 2000, // Time on fade in and out every slide
                slidesToShow: 4,
                slidesToScroll: 4,
              },
            },
            {
              breakpoint: 1280,
              settings: {
                arrows: true,
                speed: 2500, // Time on fade in and out every slide
                slidesToShow: 5,
                slidesToScroll: 5,
              },
            },
          ],
        });

      });

    }

    let testimonialCarousel = document.querySelectorAll('.js-testimonial-carousel');

    if (testimonialCarousel.length > 0) {
      testimonialCarousel.forEach(carousel => {

        $(carousel).slick({
          arrows: true, // Adds arrow nav
          dots: false, // Adds bullets nav
          autoplay: true,
          autoplaySpeed: 5000, // Time between slide changes
          speed: 1000, // Time on fade in and out every slide
          useTransform: true,
          cssEase: 'ease-in-out',
          slidesToShow: 1,
          adaptiveHeight: true,
        });
      });

    }

    let cardCarousels = document.querySelectorAll('.js-cards-carousel');
    if (cardCarousels.length > 0) {
      cardCarousels.forEach(carousel => {

        $(carousel).slick({
          arrows: true, // Adds arrow nav
          dots: false, // Adds bullets nav
          useTransform: true,
          speed: 1500, // Time on fade in and out every slide
          autoplay: false,
          cssEase: 'ease-in-out',
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        });
      });
    }

    let mediaTextGrid = document.querySelectorAll('.js-mediatext-carousel');

    if (mediaTextGrid.length > 0) {
      mediaTextGrid.forEach(carousel => {

        $(carousel).slick({
          arrows: true, // Adds arrow nav
          dots: false, // Adds bullets nav
          autoplay: false,
          speed: 1500, // Time on fade in and out every slide
          useTransform: true,
          cssEase: 'ease-in-out',
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          adaptiveHeight: true,
          // Breakpoints for responsive
          mobileFirst: true, // Makes library to work mobile first
          responsive: [
            {
              breakpoint: 601,
              settings: 'unslick',
            },
          ],
        });

      });

    }

    let customCard = document.querySelectorAll('.js-custom-card');

    let closeCard = function () {
      customCard.forEach(card => {
        if (card.classList.contains('is-open')) {
          card.classList.remove('is-open');
        }
      })
    }

    if (customCard.length > 0) {
      customCard.forEach(card => {
        let buttons = card.querySelectorAll('.js-card-cover-button');

        buttons.forEach(button => {

          button.addEventListener('click', function (e) {
            e.preventDefault();

            if (!card.classList.contains('is-open')) {
              closeCard()
              card.classList.add('is-open');

              $('.donut-counter__number', card).each(function () {
                $(this)
                  .prop('Counter', 0).prop('isDecimal', parseInt($(this).text()) == parseFloat($(this).text()))
                  .delay(300)
                  .animate({
                    Counter: $(this).text(),
                  }, {
                    duration: 1750,
                    easing: 'linear',
                    step: function (now) {
                      if ($(this).prop('isDecimal')) {
                        $(this).text(parseFloat(now).toFixed(0));
                      } else {
                        $(this).text(parseFloat(now).toFixed(2));
                      }
                    },
                  });
              });

            } else {
              card.classList.remove('is-open');
            }
          })
        })
      });
    }

    let postShare = document.querySelectorAll('.js-share-button');

    if (postShare.length > 0) {
      postShare.forEach(share => {

        let parent = share.parentElement;

        share.addEventListener('click', function (e) {
          e.preventDefault();

          if (!parent.classList.contains('is-open')) {
            parent.classList.add('is-open');
          } else {
            parent.classList.remove('is-open');
          }
        })


      });

    }

    // const r4s = window.r4s || false; // Default as false if not set



  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    animations();
  },
};
