export default function () {
  const customSelects = document.querySelectorAll('.js-custom-select');

  // Custom selects open/close
  customSelects.forEach(customSelect => {
    const optionsContainer = customSelect.querySelector('.js-custom-select-options');
    const optionsList = customSelect.querySelectorAll('.custom-select__option');
    const selectedText = customSelect.querySelector('.js-custom-select-text');

    selectedText.addEventListener('click', (e) => {

      customSelects.forEach(allCustomSelects => {
        const allContainer = allCustomSelects.querySelector('.js-custom-select-options');

        if (e.target.parentNode !== allCustomSelects) {
          allContainer.classList.remove('is-open');
        }
      });

      optionsContainer.classList.toggle('is-open');
    });

    // Set value on placeholder
    optionsList.forEach(option => {
      option.addEventListener('click', () => {
        selectedText.innerHTML = option.querySelector('label').innerHTML;
        customSelect.setAttribute('data-value', option.querySelector('label').innerHTML);
      });
    });

    // Detect all clicks on the document
    document.addEventListener('click', function (e) {
      // If user clicks inside the element, do nothing
      if (e.target.closest('.js-custom-select-text')) return;

      // If user clicks outside the element, hide it!
      // box.classList.add("js-is-hidden");
      customSelects.forEach(allCustomSelects => {
        const allContainer = allCustomSelects.querySelector('.js-custom-select-options');

        if (e.target.parentNode !== allCustomSelects) {
          allContainer.classList.remove('is-open');
        }
      });
    });
  });

}
