function accordionItem() {

  let items = document.querySelectorAll('.js-accordion-item');

  let closeItem = function () {
    items.forEach(item => {
      if(item.classList.contains('is-open')) {
        item.classList.remove('is-open');
      }
    })
  }

  if (items.length > 0) {
    items.forEach(item => {
      item.querySelector('.js-accordion-trigger').addEventListener('click', function (e) {
        e.preventDefault();

        if(!item.classList.contains('is-open')) {
          closeItem()
          item.classList.add('is-open');
        } else {
          item.classList.remove('is-open');
        }
      })
    });
  }
}

export default accordionItem
