const catBar = function () {
  let bars = document.querySelectorAll('.js-cat-bar');

  if (bars.length > 0) {

    const closeCatBar = function () {
      bars.forEach(bar => {
        bar.classList.remove('is-open');
        bar.classList.remove('is-current');
      });
    }

    const openCatBar = function () {
      if (window.innerWidth < 1024) {
        bars.forEach(function (bar) {

          let barTrigger = bar.querySelector('.js-cat-bar-trigger');

          barTrigger.addEventListener('click', function (e) {
            e.preventDefault();
            bar.classList.toggle('is-open');
          })

          bar.addEventListener('click', function (e) {
            if (e.target !== barTrigger) {
              bar.classList.remove('is-open');
            }
          })

          if ( bar.classList.contains('has-checkboxes') ) {
            let barItems = bar.querySelectorAll('.js-filter-item');

            barItems.forEach(function (barItem) {

              barItem.addEventListener('click', function (e) {
                let allOption = bar.querySelector('.cat-filter__item--all');
                if( !barItem.classList.contains('cat-filter__item--reset') ) {
                  barTrigger.textContent = e.target.textContent;
                  barItem.classList.add('is-current');
                } else {
                  barTrigger.textContent = allOption.textContent;
                  allOption.classList.add('is-current');
                }
              })
            });

          }
        })
      } else {
        closeCatBar();
      }
    }

    openCatBar();

    window.addEventListener('resize', () => {
      openCatBar();
    });

    // Detect all clicks on the document
    document.addEventListener('click', function (e) {
      // If user clicks inside the element, do nothing
      if (e.target.closest('.js-cat-bar') ) return;

      // If user clicks outside the element, hide it!
      // box.classList.add("js-is-hidden");
      closeCatBar();
    });
  }
}

export default catBar
