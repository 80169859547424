import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Observer } from 'gsap/Observer';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';


function animations() {

  document.body.classList.add('has-animations');

  gsap.registerPlugin(ScrollTrigger, Observer, ScrollToPlugin);
  // console.info('hello animations')

  gsap.from('.header', {
    // scrollTrigger: '.wp-block-cover',
    ease: 'circ.out',
    delay: 0,
    duration: 1.5,
    opacity: 0,
    y: -100,
  });

  if( document.querySelector('.wp-block-cover__inner-container') ) {
    gsap.from('.wp-block-cover__inner-container', {
      // scrollTrigger: '.wp-block-cover',
      ease: 'power2.out',
      duration: 1.3,
      delay: 0.1,
      opacity: 0,
      y: 100,
    });
  }


  if( document.querySelector('.contact-cta .container') ) {
    gsap.from('.contact-cta .container', {
      scrollTrigger: '.contact-cta',
      duration: 1,
      opacity: 0,
      delay: 0,
      y: 50,
    });
  }


  if( document.querySelector('.list-show-more') ) {
    gsap.from('.list-show-more', {
      // scrollTrigger: '.contact-cta',
      duration: 0.5,
      opacity: 0,
      delay: 0.2,
      y: 10,
    });
  }


  if( document.querySelector('.single-post__thumb') ) {
    gsap.from('.single-post__thumb', {
      // scrollTrigger: '.contact-cta',
      duration: 0.5,
      opacity: 0,
      delay: 0.2,
      y: 10,
    });
  }


  // gsap.from('.wp-block-cover .is-style-link-arrow-right', {
  //   scrollTrigger: '.wp-block-cover',
  //   duration: 1.4,
  //   delay: 0.6,
  //   opacity: 0,
  //   y: 50
  // });

  gsap.utils.toArray('.post-grid__item').forEach(element => {
    gsap.from(element, {
      scrollTrigger: element,
      duration: 0.4,
      delay: $(element).index() * 0.05,
      opacity: 0,
      y: 150,
    });
  });

  gsap.utils.toArray('.publications__list .publication').forEach(element => {
    gsap.from(element, {
      scrollTrigger: element,
      duration: 1,
      delay: $(element).index() * 0.05,
      opacity: 0,
      y: 100,
    });
  });

  if( document.querySelector('.professional') ) {
    gsap.utils.toArray('.professional').forEach(element => {
      gsap.from(element, {
        scrollTrigger: element.parentNode,
        duration: 0.5,
        delay: $(element).index() * 0.1,
        opacity: 0,
        scale: 0.5,
      });
    });
  }

  gsap.utils.toArray('.js-mediatext-carousel .wp-block-media-text').forEach(element => {
    if ($(element).hasClass('has-media-on-the-right')) {
      gsap.from($(element).find('.wp-block-media-text__media'), {
        scrollTrigger: element,
        duration: 1,
        delay: $(element).index() * 0.1,
        opacity: 0,
        x: '+=200',
      });
    } else {
      gsap.from($(element).find('.wp-block-media-text__media'), {
        scrollTrigger: element,
        duration: 1,
        delay: $(element).index() * 0.1,
        opacity: 0,
        x: '-=200',
      });
    }
  });

  if( document.querySelector('.js-custom-card') ) {
    gsap.utils.toArray('.js-custom-card').forEach(element => {
      gsap.from(element, {
        scrollTrigger: element,
        duration: 0.75,
        delay: $(element).index() * 0.1,
        opacity: 0,
        y: '+=100',
        onComplete: function () {
          if($(element).parents('.commitments-grid').length > 0) {
            element.removeAttribute('style');
          }
        },
      });
    });
  }

  if( document.querySelector('.hotspot__point') ) {
    gsap.utils.toArray('.hotspot__point').forEach(element => {
      gsap.from(element, {
        scrollTrigger: element,
        duration: 0.5,
        delay: $(element).index() * 0.2,
        opacity: 0,
        y: '-=100',
      });
    });
  }


  gsap.utils.toArray('.blog-post').forEach(element => {
    gsap.from(element, {
      scrollTrigger: element,
      duration: 0.5,
      delay: $(element).index() * 0.2,
      opacity: 0,
      y: 250,
    });
  });
}

export default animations
